/**
 * 이벤트를 나타내는 인터페이스입니다.
 */
interface IEvent {
  handler: (...args: any[]) => void;
  persist: boolean;
}

/**
 * 이벤트 맵을 나타내는 인터페이스입니다.
 */
interface IEventMap {
  [key: string]: IEvent[];
}

/**
 * 이벤트 관리자를 나타내는 클래스입니다.
 */
class CEvent {
  eventMap: IEventMap = {};

  constructor() {}

  /**
   * 지정된 키에 대해 지속적인 이벤트 핸들러를 등록합니다.
   * @param {string} key - 이벤트 키.
   * @param {function} handler - 이벤트 핸들러 함수.
   */
  on(key: string, handler: (...args: any[]) => void): void {
    const events = this.eventMap[key] || [];
    events.push({ handler, persist: true });
    this.eventMap[key] = events;
  }

  /**
   * 지정된 키에 대해 일회성 이벤트 핸들러를 등록합니다.
   * @param {string} key - 이벤트 키.
   * @param {function} handler - 이벤트 핸들러 함수.
   */
  once(key: string, handler: (...args: any[]) => void): void {
    const events = this.eventMap[key] || [];
    events.push({ handler, persist: false });
    this.eventMap[key] = events;
  }

  /**
   * 지정된 키의 이벤트를 발생시키고, 모든 등록된 핸들러를 호출합니다.
   * 일회성 핸들러는 호출 후 제거됩니다.
   * @param {string} key - 이벤트 키.
   * @param {...any} args - 이벤트 핸들러에 전달할 인수들.
   */
  emit(key: string, ...args: any[]): void {
    const events = this.eventMap[key] || [];
    events.forEach((event) => event.handler(...args));
    this.eventMap[key] = events.filter((event) => event.persist);
  }

  /**
   * 지정된 키에 대한 특정 이벤트 핸들러를 제거합니다.
   * 핸들러가 제공되지 않으면 해당 키의 모든 핸들러를 제거합니다.
   * @param {string} key - 이벤트 키.
   * @param {function} [handler] - 제거할 이벤트 핸들러 함수.
   */
  remove(key: string, handler?: (...args: any[]) => void): void {
    if (handler) {
      const events = this.eventMap[key] || [];
      this.eventMap[key] = events.filter((event) => event.handler !== handler);
    } else {
      delete this.eventMap[key];
    }
  }

  /**
   * 모든 키에 대한 모든 이벤트 핸들러를 제거합니다.
   */
  cleanup(): void {
    Object.keys(this.eventMap).forEach((key) => delete this.eventMap[key]);
  }
}

/**
 * Nuxt 앱에서 이벤트 관리자를 사용하는 훅입니다.
 * @returns {CEvent} 이벤트 관리자 인스턴스.
 */
export function useEvent(): CEvent {
  const { vueApp } = useNuxtApp();
  if (!vueApp._context.provides.$cevent) {
    vueApp.provide("$cevent", new CEvent());
  }
  return vueApp._context.provides.$cevent;
}
